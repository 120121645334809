import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function login(params) {
    try {
        let res = request('post', urlFor(AUTH_API_BASE_URL, '/login'), {
            data: params
        });
        return res
    } catch (e) {
        return e
    }

}

export async function permissions(params) {
    try {
        let res = request('get', urlFor(AUTH_API_BASE_URL, '/checkauth'), {
            auth: true,
            data: params
        });
        return res
    } catch (e) {
        return e
    }

}
