import * as CouponService from '@/services/apis/coupon'
const state = {
}

const actions = {
  async getCoupon({ }, params) {
    try {
      const res = await CouponService.getCoupon(params)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getCashback({ }, params) {
    try {
      let text = ""
      for (let i in params) {
        text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
      }
      const res = await CouponService.getCashback(text)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getCashbackTotal({ }, params) {
    try {
      const res = await CouponService.getCashbackTotal(params)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },


  async getCouponDetail({ }, params) {
    try {
      const res = await CouponService.getCouponDetail(params)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async addCoupon({ }, data) {
    try {
      const res = await CouponService.addCoupon(data)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async deleteCoupon({ }, params) {
    try {
      const res = await CouponService.deleteCoupon(params)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async deleteCouponSet({ }, params) {
    try {
      const res = await CouponService.deleteCouponSet(params)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
}

export default {
  state,
  actions,
}
