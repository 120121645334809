import * as LoginService from '@/services/apis/login'
import * as settingsWebService from '@/services/apis/settingsWeb'
import Swal from 'sweetalert2'

const state = {
  token: null,
  permissions: [],
  permissionsEdit: [],
  userinfo: {
    name: null,
    role: null,
    auth2fa: 0,
  },
  webinfoname: null,
}

const getters = {
  getToken: state => {
    return state.token
  },
  getPermissions: state => {
    return state.permissions
  },
  getPermissionsEdit: state => {
    return state.permissionsEdit
  },
  getuserInfo: state => {
    return state.userinfo
  },
  getWebName: state => {
    return state.webinfoname
  },
}

const mutations = {
  updateToken: (state, payload) => {
    state.token = payload
  },
  updatePermissions: (state, payload) => {
    state.permissions = payload
  },
  updatePermissionsEdit: (state, payload) => {
    state.permissionsEdit = payload
  },
  updateUserInfo: (state, payload) => {
    state.userinfo.name = payload.name
    state.userinfo.role = payload.role
    state.userinfo.auth2fa = payload.auth2fa
  },
  updateWebInfo: (state, payload) => {
    state.webinfoname = payload
  },
  resetAuth: (state, payload) => {
    state.token = null
    state.permissions = []
    state.userinfo.name = null
    state.userinfo.role = null
    state.userinfo.auth2fa = 0
  },
}

const actions = {
  async login({ commit, dispatch }, params) {
    try {
      let res = await LoginService.login(params)
      if (res.status === 201) {
        commit('updateToken', res.data.access_token)
        // await dispatch('permissions');
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async permissions({ commit }) {
    try {
      let res = await LoginService.permissions()

      // mock up permission bonus-summary
      res.data.permissions.push({ id: 30, page: 'bonus-summary', path: '/bonus-summary', use: 1 })

      if (res.status === 200) {
        let temp = []
        let tempEdit = []
        res.data.permissions.forEach(element => {
          if (element.use) {
            temp.push(element.page)
          }
          if (element.edit) {
            tempEdit.push(element.page)
          }
        })
        commit('updatePermissions', temp)
        commit('updatePermissionsEdit', tempEdit)
        commit('updateUserInfo', res.data)
      } else {
        commit('updatePermissions', [])
        commit('updatePermissionsEdit', [])
        throw Error(JSON.stringify({ message: 'มีบางอย่างผิดพลาด' }))
      }
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async webSettingInfo({ commit }) {
    try {
      let result = await settingsWebService.getWebInfo()
      commit('updateWebInfo', result.data.name)
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async logout({ commit }, param) {
    if (param && param.type === 'warning') {
      Swal.fire({
        icon: 'error',
        title: param.message,
      }).then(action => {
        commit('resetAuth')
        location.reload()
      })
    } else {
      commit('resetAuth')
      location.reload()
    }
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
