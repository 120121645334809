import * as SettingsWebService from '@/services/apis/settingsWeb'
const state = {
  playsound: false,
  timezone: 'Asia/Bangkok',
}

const getters = {
  timezone: (state) => state.timezone,
}

const mutations = {
  SET_TIMEZONE: (state, timezone) => {
    state.timezone = timezone;
  },
}

const actions = {
  async getSettingWebInfo({ commit }) {
    try {
      const res = await SettingsWebService.getSettingWebInfo()
      if (res.data?.tz) {
        commit('SET_TIMEZONE', res.data?.tz);
      }
      return res.data
    } catch (err) {
      throw err
    }
  },
  async updateSettingWebInfo({ }, data) {
    try {
      const res = await SettingsWebService.updateSettingWebInfo(data)
      if (res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getSettingWebColor({ },) {
    try {
      const res = await SettingsWebService.getSettingWebColor()
      return res.data
    } catch (err) {
      throw err
    }
  },
  async updateSettingWebColor({ }, data) {
    try {
      const res = await SettingsWebService.updateSettingWebColor(data)
      if (res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getSettingWebWheel({ },) {
    try {
      const res = await SettingsWebService.getSettingWebWheel()
      return res.data
    } catch (err) {
      throw err
    }
  },
  async updateSettingWebWheel({ }, data) {
    try {
      const res = await SettingsWebService.updateSettingWebWheel(data)
      if (res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getSettingWebAffiliate({ },) {
    try {
      const res = await SettingsWebService.getSettingWebAffiliate()
      return res.data
    } catch (err) {
      throw err
    }
  },
  async updateSettingWebAffiliate({ }, data) {
    try {
      const res = await SettingsWebService.updateSettingWebAffiliate(data)
      if (res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getSettingWebCashback({ },) {
    try {
      const res = await SettingsWebService.getSettingWebCashback()
      return res.data
    } catch (err) {
      throw err
    }
  },
  async updateSettingWebCashback({ }, data) {
    try {
      const res = await SettingsWebService.updateSettingWebCashback(data)
      if (res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getSettingGames({ },) {
    try {
      const res = await SettingsWebService.getSettingGames()
      return res.data
    } catch (err) {
      throw err
    }
  },
  async updateSettingGames({ }, data) {
    try {
      const res = await SettingsWebService.updateSettingGames(data)
      if (res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  getPlaySound({ },) {
    return state.playsound
  },
  setPlaySound({ }, data) {
    console.log(data);
    state.playsound = data
    return state.playsound
  },
  async putSettingUpload({ }, data) {
    try {
      const res = await SettingsWebService.putSettingUpload(data)
      // console.log('res :>> ', res);
      if (res.status === 200) {
        return res
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async postSettingFeature({ }, data) {
    try {
      const res = await SettingsWebService.postSettingFeature(data)
      // console.log('res :>> ', res);
      if (res.status === 201) {
        return res
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getSettingFeature({ },) {
    try {
      const res = await SettingsWebService.getSettingFeature()
      if (res.status === 200) {
        return res.data
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async putSettingOtpAdmin({ }, data) {
    try {
      const res = await SettingsWebService.putSettingOtpAdmin(data)
      if (res.status === 200) {
        return res
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
}


export default {
  state,
  mutations,
  getters,
  actions,
}
