<template>
  <v-form ref="form" v-model="formValid">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <h2>{{ $t('user.head') }}</h2>
        <v-divider class="mx-3" vertical />
        <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
      </v-col>
      <v-divider class="mx-3" vertical />

      <!-- member detail card -->
      <v-col cols="12">
        <v-card>
          <v-card-title> {{ $t('user.form_title') }} </v-card-title>
          <v-divider class="mx-3" />
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field
                  v-model="datas.phone"
                  :label="$t('user.tel')"
                  outlined
                  dense
                  readonly
                  disabled
                />
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field v-model="datas.name" :label="$t('user.fullname')" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field v-model="datas.line" label="Line" outlined dense />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="6" lg="4" v-if="bankCountry === 'TH'">
                <v-select
                  :label="$t('user.bank')"
                  v-model="datas.bank"
                  :rules="[v => !!v || '']"
                  :items="bankItems"
                  item-text="label"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4"  v-if="bankCountry === 'KR' || bankCountry === 'LA'">
                <v-select
                  :label="$t('user.bank')"
                  v-model="datas.bank_code"
                  :rules="[v => !!v || '']"
                  :items="bankItems"
                  item-text="label"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field
                  @keypress="filter(event)"
                  v-model="datas.bank_account"
                  :rules="[v => !!v || '']"
                  :label="$t('user.bank_account')"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field
                  v-model="datas.bank_name"
                  :rules="[v => !!v || '']"
                  :label="$t('user.account_name')"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-card-actions>
          <!-- <v-card-actions>
          <v-row>
            <v-col cols="4" xs="12">
              <v-text-field label="Turn date" outlined dense />
            </v-col>
            <v-col cols="4" xs="12">
              <v-text-field label="Turn over before" outlined dense />
            </v-col>
            <v-col cols="4" xs="12">
              <v-text-field label="Turn over" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions> -->
          <!-- <v-card-actions>
            <v-row>
              <v-col cols="4" xs="12">
                <v-text-field v-model="datas.email" label="อีเมล" outlined dense />
              </v-col>
              <v-col cols="4" xs="12">
                <v-select v-model="datas.type" label="สถานะ" :items="statusItems" outlined dense readonly disabled />
              </v-col>
              <v-col cols="4" xs="12">
                <v-text-field v-model="datas.ref_key" label="Ref Key" readonly disabled outlined dense />
              </v-col>
            </v-row>
          </v-card-actions> -->
          <v-card-actions>
            <v-row>
              <!-- <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field v-model="datas.turnOver" label="turn over" outlined dense readonly />
              </v-col> -->
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field v-model="datas.commission_percent" type="number" label="Commission Percent" outlined
                  dense />
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-select v-model="datas.cashback" :label="$t('cashback')" :items="acceptItems" outlined dense />
              </v-col>
              <!-- <v-col cols="4" xs="12">
                <v-text-field v-model="datas.member_game" label="member_game" readonly disabled outlined dense />
              </v-col> -->
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-select v-model="datas.partner" :label="$t('partner')" :items="partnerItems" outlined dense />
              </v-col>
              <v-col v-if="!datas.partner" cols="12" xs="12" md="6" lg="4">
                <v-select v-model="partnerName" :label="$t('partner_under')" :items="partnerItemsName" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4" v-if="bankCountry !== 'TH'">
                <v-select v-model="countryCode" :label="$t('user.country')" :items="countryCodeItems" outlined dense />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-divider class="mx-3" />
        </v-card>
      </v-col>

      <!-- username & password card -->
      <v-col cols="12">
        <v-card>
          <v-card-title>{{ $t('user.user_data') }} </v-card-title>
          <v-divider class="mx-3" />
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="4">
                <v-text-field v-model="datas.phone" label="Username" disabled outlined dense readonly />
              </v-col>
              <v-col cols="12" xs="12" md="4">
                <v-text-field v-model="datas.password" label="New Password" outlined dense type="password" />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-divider class="mx-3" />
          <v-card-actions class="form-user-edit" cols="12" xs="12" md="4">
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'user' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">{{ $t('back') }}</span>
            </v-btn>
            <!-- <v-btn color="info" :loading="loading" @click="reset">
              <v-icon>mdi-refresh</v-icon>
              <span class="px-3">reset turn</span>
            </v-btn> -->
            <v-btn color="success" :loading="loading" @click="save" class="px-3">
              <v-icon>mdi-content-save</v-icon>
              <span class="px-3">{{ $t('save') }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- turnover card -->
      <v-col cols="12">
        <v-card>
          <v-card-title>{{ $t('user.turn_over_title') }} </v-card-title>
          <v-divider class="mx-3" />
          <v-col cols="12" class="pa-6">
            <v-row class="mx-0">- หากต้องการปรับยอดเทิร์นที่ต้องทำ ให้ใส่เทิร์นที่จะปรับในช่อง<b class="px-2">"Turn
                ที่ต้องทำ"</b>และกดปุ่ม <b class="pl-2">"ปรับ Turn"</b></v-row>
            <v-row class="mx-0">- หากต้องการปรับยอดเทิร์นที่เล่นแล้วของยูส ให้ใส่เทิร์นที่จะปรับในช่อง<b
                class="px-2">"Turn ที่เล่นแล้ว"</b>และกดปุ่ม <b class="pl-2">"ปรับ Turn"</b></v-row>
            <v-row class="mx-0">- หากต้องการเคลียร์เทิร์นแต่ละยอด ให้กดปุ่ม<b class="pl-2">"Clear Turn"</b></v-row>
            <v-row class="mx-0">- หากต้องการเคลียร์เทิร์นทั้งหมด ให้กดปุ่ม<b class="pl-2">"Reset Turn"</b></v-row>
          </v-col>

          <v-divider class="mx-3" />

          <v-col class="px-0" cols="12">
            <v-card-title>{{ $t('user.turn_over_coupon') }}</v-card-title>
            <v-card-actions class="py-0">
              <v-col cols="12" no-gutters v-if="couponTurnoverList.length > 0">
                <v-row v-for="(i, n) in couponTurnoverList" :key="n">
                  <v-col cols="3">
                    <v-text-field v-model="i.key" :label="$t('coupon_code')" outlined dense readonly />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="i.turnToDo"
                      :label="$t('user.turn_over_todo')"
                      outlined
                      dense
                      @input="updateTurnoverValue({ ...i, type: 'coupon', label: 'turnToDo' }, n)"
                      @click:append="resetDefaultValue({ ...i, type: 'coupon', label: 'turnToDo' }, n)"
                      :append-icon="i.turnToDo === defaultTurnoverList.data.coupon[n].turnToDo ? '' : 'mdi-refresh'"
                      :error-messages="generateErrorMessages(i, 'turnToDo')" />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="i.turnDone"
                      :label="$t('user.turn_over_done')"
                      outlined
                      dense
                      @input="updateTurnoverValue({ ...i, type: 'coupon', label: 'turnDone' }, n)"
                      @click:append="resetDefaultValue({ ...i, type: 'coupon', label: 'turnDone' }, n)"
                      :append-icon="i.turnDone === defaultTurnoverList.data.coupon[n].turnDone ? '' : 'mdi-refresh'"
                      :error-messages="generateErrorMessages(i, 'turnDone')" />
                  </v-col>
                  <v-col cols="3" style="text-align: end">
                    <v-btn color="info" outlined :loading="loading" class="px-3"
                      @click="validateCustomReset({ ...i, type: 'coupon' })">
                      <v-icon>mdi-delete</v-icon>
                      <span class="px-3">Clear Turn</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-row v-if="couponTurnoverList.length === 0">
                <v-col cols="4" xs="4" md="4" lg="4" class="mb-3"> {{ $t('no_have') }} </v-col>
              </v-row>
            </v-card-actions>
          </v-col>

          <v-divider class="mx-3" />

          <v-col class="px-0" cols="12">
            <v-card-title> {{ $t('user.turn_over_promotion') }}</v-card-title>
            <v-card-actions class="py-0">
              <v-col cols="12" no-gutters v-if="promotionTurnoverList.length > 0">
                <v-row v-for="(i, n) in promotionTurnoverList" :key="n">
                  <v-col cols="3">
                    <v-text-field
                      v-model="i.name"
                      :label="$t('user.turn_over_promotion_name')"
                      outlined
                      dense
                      readonly
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="i.turnToDo"
                      :label="$t('user.turn_over_todo')"
                      outlined
                      dense
                      @input="updateTurnoverValue({ ...i, type: 'promotion', label: 'turnToDo' }, n)"
                      @click:append="resetDefaultValue({ ...i, type: 'promotion', label: 'turnToDo' }, n)"
                      :append-icon="i.turnToDo === defaultTurnoverList.data.promotion[n].turnToDo ? '' : 'mdi-refresh'"
                      :error-messages="generateErrorMessages(i, 'turnToDo')" />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="i.turnDone"
                      :label="$t('user.turn_over_done')"
                      outlined
                      dense
                      @input="updateTurnoverValue({ ...i, type: 'promotion', label: 'turnDone' }, n)"
                      @click:append="resetDefaultValue({ ...i, type: 'promotion', label: 'turnDone' }, n)"
                      :append-icon="i.turnDone === defaultTurnoverList.data.promotion[n].turnDone ? '' : 'mdi-refresh'"
                      :error-messages="generateErrorMessages(i, 'turnDone')" />
                  </v-col>
                  <v-col cols="3" style="text-align: end">
                    <v-btn color="info" outlined :loading="loading" class="px-3"
                      @click="validateCustomReset({ ...i, type: 'promotion' })">
                      <v-icon>mdi-delete</v-icon>
                      <span class="px-3">Clear Turn</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-row v-if="promotionTurnoverList.length === 0">
                <v-col cols="4" xs="4" md="4" lg="4" class="mb-3"> {{ $t('no_have') }} </v-col>
              </v-row>
            </v-card-actions>
          </v-col>

          <v-divider class="mx-3" />

          <v-col class="px-0" cols="12">
            <v-card-title> {{ $t('user.turn_over_cashback') }}</v-card-title>
            <v-card-actions class="py-0">
              <v-col cols="12" no-gutters v-if="cashbackTurnoverList.length > 0">
                <v-row v-for="(i, n) in cashbackTurnoverList" :key="n">
                  <v-col cols="3"> </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="i.turnToDo"
                      :label="$t('user.turn_over_todo')"
                      outlined
                      dense
                      @input="updateTurnoverValue({ ...i, type: 'cashback', label: 'turnToDo' }, n)"
                      @click:append="resetDefaultValue({ ...i, type: 'cashback', label: 'turnToDo' }, n)"
                      :append-icon="i.turnToDo === defaultTurnoverList.data.cashback[n].turnToDo ? '' : 'mdi-refresh'"
                      :error-messages="generateErrorMessages(i, 'turnToDo')" />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="i.turnDone"
                      :label="$t('user.turn_over_done')"
                      outlined
                      dense
                      @input="updateTurnoverValue({ ...i, type: 'cashback', label: 'turnDone' }, n)"
                      @click:append="resetDefaultValue({ ...i, type: 'cashback', label: 'turnDone' }, n)"
                      :append-icon="i.turnDone === defaultTurnoverList.data.cashback[n].turnDone ? '' : 'mdi-refresh'"
                      :error-messages="generateErrorMessages(i, 'turnDone')" />
                  </v-col>
                  <v-col cols="3" style="text-align: end">
                    <v-btn color="info" outlined :loading="loading" class="px-3"
                      @click="validateCustomReset({ ...i, type: 'cashback' })">
                      <v-icon>mdi-delete</v-icon>
                      <span class="px-3">Clear Turn</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-row v-if="cashbackTurnoverList.length === 0">
                <v-col cols="4" xs="4" md="4" lg="4" class="mb-3"> {{ $t('no_have') }} </v-col>
              </v-row>
            </v-card-actions>
          </v-col>

          <v-divider class="mx-3" />

          <v-col class="px-0" cols="12">
            <v-card-title>{{ $t('user.turn_over_suggest') }}</v-card-title>
            <v-card-actions class="py-0">
              <v-col cols="12" no-gutters v-if="affiliateTurnoverList.length > 0">
                <v-row v-for="(i, n) in affiliateTurnoverList" :key="n">
                  <v-col cols="3"> </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="i.turnToDo"
                      :label="$t('user.turn_over_todo')"
                      outlined
                      dense
                      @input="updateTurnoverValue({ ...i, type: 'affiliate', label: 'turnToDo' }, n)"
                      @click:append="resetDefaultValue({ ...i, type: 'affiliate', label: 'turnToDo' }, n)"
                      :append-icon="i.turnToDo === defaultTurnoverList.data.affiliate[n].turnToDo ? '' : 'mdi-refresh'"
                      :error-messages="generateErrorMessages(i, 'turnToDo')" />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="i.turnDone"
                      :label="$t('user.turn_over_done')"
                      outlined
                      dense
                      @input="updateTurnoverValue({ ...i, type: 'affiliate', label: 'turnDone' }, n)"
                      @click:append="resetDefaultValue({ ...i, type: 'affiliate', label: 'turnDone' }, n)"
                      :append-icon="i.turnDone === defaultTurnoverList.data.affiliate[n].turnDone ? '' : 'mdi-refresh'"
                      :error-messages="generateErrorMessages(i, 'turnDone')" />
                  </v-col>
                  <v-col cols="3" style="text-align: end">
                    <v-btn color="info" outlined :loading="loading" class="px-3"
                      @click="validateCustomReset({ ...i, type: 'affiliate' })">
                      <v-icon>mdi-delete</v-icon>
                      <span class="px-3">Clear Turn</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-row v-if="affiliateTurnoverList.length === 0">
                <v-col cols="4" xs="4" md="4" lg="4" class="mb-3"> {{ $t('no_have') }} </v-col>
              </v-row>
            </v-card-actions>
          </v-col>

          <v-divider class="mx-3" />

          <v-col class="px-0" cols="12">
            <v-card-title>{{ $t('user.turn_over_activity') }}</v-card-title>
            <v-card-actions class="py-0">
              <v-col cols="12" no-gutters v-if="activityTurnoverList.length > 0">
                <v-row v-for="(i, n) in activityTurnoverList" :key="n">
                  <v-col cols="3">
                    <v-text-field
                      v-model="i.name"
                      :label="$t('user.turn_over_activity_name')"
                      outlined
                      dense
                      readonly
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="i.turnToDo"
                      :label="$t('user.turn_over_todo')"
                      outlined
                      dense
                      @input="updateTurnoverValue({ ...i, type: 'activity', label: 'turnToDo' }, n)"
                      @click:append="resetDefaultValue({ ...i, type: 'activity', label: 'turnToDo' }, n)"
                      :append-icon="i.turnToDo === defaultTurnoverList.data.activity[n].turnToDo ? '' : 'mdi-refresh'"
                      :error-messages="generateErrorMessages(i, 'turnToDo')" />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="i.turnDone"
                      :label="$t('user.turn_over_done')"
                      outlined
                      dense
                      @input="updateTurnoverValue({ ...i, type: 'activity', label: 'turnDone' }, n)"
                      @click:append="resetDefaultValue({ ...i, type: 'activity', label: 'turnDone' }, n)"
                      :append-icon="i.turnDone === defaultTurnoverList.data.activity[n].turnDone ? '' : 'mdi-refresh'"
                      :error-messages="generateErrorMessages(i, 'turnDone')" />
                  </v-col>
                  <v-col cols="3" style="text-align: end">
                    <v-btn color="info" outlined :loading="loading" class="px-3"
                      @click="validateCustomReset({ ...i, type: 'activity' })">
                      <v-icon>mdi-delete</v-icon>
                      <span class="px-3">Clear Turn</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-row v-if="activityTurnoverList.length === 0">
                <v-col cols="4" xs="4" md="4" lg="4" class="mb-3"> {{ $t('no_have') }} </v-col>
              </v-row>
            </v-card-actions>
          </v-col>

          <v-divider class="mx-3" />

          <v-col class="px-0" cols="12">
            <v-card-title>{{ $t('user.turn_over_summary') }} </v-card-title>
            <v-card-actions class="pt-0">
              <v-data-table mobile-breakpoint="0" class="custom_table_class"
                style="width: 100%; border: thin solid rgba(94, 86, 105, 0.14); border-radius: 1rem" :headers="headers"
                :items="[
                  {
                    action: $t('user.current'),
                    turnoverDone: turnoverDoneBefore,
                    turnoverToDo: turnoverToDoBefore,
                    turnoverUpdate: '-',
                    turnoverRemain: datas.turnOver,
                  },
                  {
                    action: $t('user.after'),
                    turnoverDone: turnoverDoneAfter,
                    turnoverToDo: turnoverToDoAfter,
                    turnoverUpdate: turnoverUpdate,
                    turnoverRemain: turnoverResult,
                  },
                ]"
                :no-data-text="$t('not_found')"
                :no-results-text="$t('not_found')"
                :footer-props="{
                  'items-per-page-text': '',
                  'items-per-page-options': [],
                }"
                :hide-default-header="true"
                :hide-default-footer="true"
              >
                <template slot="header">
                  <thead>
                    <tr>
                      <th class="th-col" v-for="header in headers" :key="header.value">
                        <span style="font-size: 1em; padding: 1rem 0">{{ header.text }}</span>
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:[`item.turnoverDone`]="{ item }">
                  <span>{{ item.turnoverDone | currency }}</span>
                </template>
                <template v-slot:[`item.turnoverToDo`]="{ item }">
                  <span>{{ item.turnoverToDo | currency }}</span>
                </template>
                <template v-slot:[`item.turnoverUpdate`]="{ item }">
                  <div style="display: flex; align-items: center">
                    <v-icon :style="{
                      marginLeft: item.turnoverUpdate === '-' || item.turnoverUpdate == 0 ? 0 : '-6px',
                      color:
                        item.turnoverUpdate === '-' || item.turnoverUpdate == 0
                          ? '#5e5669ad'
                          : (turnoverDoneBefore !== turnoverDoneAfter || turnoverToDoBefore !== turnoverToDoAfter) &&
                            item.turnoverUpdate > 0
                            ? '#f74b6c'
                            : '#0d9',
                    }">
                      {{
                        item.turnoverUpdate === '-' || item.turnoverUpdate == 0
                        ? ''
                        : (turnoverDoneBefore !== turnoverDoneAfter || turnoverToDoBefore !== turnoverToDoAfter) &&
                          item.turnoverUpdate > 0
                          ? 'mdi-menu-up'
                          : 'mdi-menu-down'
                      }}
                    </v-icon>
                    <span v-if="item.turnoverUpdate === '-'">{{ item.turnoverUpdate }}</span>
                    <span v-else :style="{
                      color:
                        item.turnoverUpdate === '-' || item.turnoverUpdate == 0
                          ? '#5e5669ad'
                          : (turnoverDoneBefore !== turnoverDoneAfter || turnoverToDoBefore !== turnoverToDoAfter) &&
                            item.turnoverUpdate > 0
                            ? '#f74b6c'
                            : '#0d9',
                    }">{{ Math.abs(item.turnoverUpdate) | currency }}</span>
                  </div>
                </template>
                <template v-slot:[`item.turnoverRemain`]="{ item }">
                  <span>{{ item.turnoverRemain | currency }}</span>
                </template>
              </v-data-table>
            </v-card-actions>
          </v-col>
          <v-divider class="mx-3" />

          <v-card-actions class="form-user-edit" cols="12" xs="12" md="4">
            <v-spacer />
            <v-btn color="error" :loading="loading" @click="validateReset">
              <v-icon>mdi-refresh</v-icon>
              <span class="px-3">reset turn</span>
            </v-btn>
            <v-btn color="info" outlined :disabled="disabledUpdateTurnoverButton" :loading="loading"
              @click="validateCustomUpdateTurnover" class="px-3">
              <v-icon>mdi-content-save</v-icon>
              <span class="px-3">{{ $t('user.edit_turn_over') }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import { KRBanks } from '@/utils/KRBanks.ts'
import { LABanks } from '@/utils/LABanks.ts'
import { KHBanks } from '@/utils/KHBanks.ts'


export default {
  data() {
    return {
      formValid: true,
      formRule: {
        // username: [
        //   value => !!value || 'username is required.',
        //   value => (!!value && value.length > 5) || 'need more than 5 characters',
        // ],
        password: [
          value => !!value || 'password is required.',
          value => (!!value && value.length > 5) || 'need more than 5 characters',
        ],
        // phone: [
        //   value => !!value || 'phone is required.',
        //   value => ((!!value && value.length >= 8) && (!!value && value.length <= 16)) || 'need phone 8-16 characters',
        // ],
      },
      loading: false,
      datas: {
        id: null,
        phone: '',
        name: '',
        bank: '',
        bank_name: '',
        bank_account: '',
        email: null,
        line: null,
        type: null,
        ref_key: null,
        commission_percent: 0,
        cashback: null,
        turnOver: 0,
        member_game: null,
        password: null,
        partner: null,
      },
      countryCode: null,
      partnerName: null,
      breadcrumbsItems: [
        {
          text: this.$t('user.head_list'),
          disabled: false,
          href: '/user',
        },
        {
          text: this.$t('user.edit_member'),
          disabled: true,
          href: '',
        },
      ],
      statusItems: [
        {
          text: this.$t('member'),
          value: 'member',
        },
      ],
      acceptItems: [
        {
          text: this.$t('yes'),
          value: true,
        },
        {
          text: this.$t('not'),
          value: false,
        },
      ],
      partnerItems: [
        {
          text: this.$t('yes'),
          value: true,
        },
        {
          text: this.$t('not'),
          value: false,
        },
      ],
      partnerItemsName: [{ text: this.$t('no'), value: 0 }],
      countryCodeItems: [
        // {text: 'ไม่มี', value: 0},
        {text: 'ลาว (Laos - LA)', value: 'LA'},
        {text: 'เกาหลี (Korea - KR)', value: 'KR'},
        {text: 'ไทย (Thai - TH)', value: 'TH'},
        {text: 'อังกฤษ (England - EN)', value: 'EN'},
        {text: 'เวียดนาม (Vietnam - VN)', value: 'VN'},
        {text: 'พม่า (Myanmar - MM)', value: 'MM'},
        {text: 'กัมพูชา (Cambodia - KH)', value: 'KH'}],
      THBanks: [],
      bankItems: [],
      underUse: [],
      bankCountry: '',
      id: this.$route.params.id,
      turnOver: {},
      turnoverToDoBefore: 0,
      turnoverDoneBefore: 0,
      turnoverToDoAfter: 0,
      turnoverDoneAfter: 0,
      turnoverUpdate: 0,
      turnoverResult: 0,
      defaultTurnoverList: [],
      affiliateTurnoverList: [],
      cashbackTurnoverList: [],
      couponTurnoverList: [],
      promotionTurnoverList: [],
      activityTurnoverList: [],
      errorInputTurnDoneList: [],
      headers: [
        {
          text: 'Turnover ',
          value: 'action',
          sortable: false,
        },
        {
          text: this.$t('user.turnover_done'),
          value: 'turnoverDone',
          sortable: false,
        },
        {
          text: this.$t('user.turnover_todo'),
          value: 'turnoverToDo',
          sortable: false,
        },
        {
          text: this.$t('user.turnover_update'),
          value: 'turnoverUpdate',
          sortable: false,
        },
        {
          text: this.$t('user.turnover_remain'),
          value: 'turnoverRemain',
          sortable: false,
        },
      ],
    }
  },
  async created() {
    try {
      let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
      this.bankSystem = settingWebInfo.bank_system;

      await this.$store.dispatch('getTypeBankData').then(result => {
        this.THBanks = result
      })

      this.$store.dispatch('getMemberTurnOver', this.$route.params.id).then(result => {
        this.setDefaultData(result)
      })
      this.$store
        .dispatch('getMember', {
          id: this.id,
          // type: 'member',
          page: 1,
          rows: 1,
        })
        .then(result => {
          this.data = result
          this.datas = {
            id: this.data.data[0].id,
            phone: this.data.data[0].phone,
            name: this.data.data[0].name,
            bank: this.data.data[0].bank,
            bank_name: this.data.data[0].bank_name,
            bank_account: this.data.data[0].bank_account,
            bank_code: this.data.data[0].bank_code,
            email: this.data.data[0].email,
            line: this.data.data[0].line,
            type: this.data.data[0].type,
            partner: this.data.data[0].type === 'member' ? false : true,
            ref_key: this.data.data[0].ref_key,
            commission_percent: this.data.data[0].commission_percent,
            cashback: this.data.data[0].cashback ? true : false,
            member_game: this.data.data[0].member_game ? this.data.data[0].member_game.username : null,
            turnOver: this.turnOver.remaining_turnover,
          }
          this.countryCode = this.countryCodeItems.filter(item => item.value === this.data.data[0].country_code)[0]?.value;
        })

      // get partner our
      let partnerOur = 0
      await this.$store
        .dispatch('getPartnerour', this.id)
        .then(result => {
          result.data.map(
            el => (
              this.partnerItemsName.push({ text: el.name, value: el.id }),
              el.is_current_partner ? ((this.partnerName = el.id), (partnerOur = el.id)) : null
            ),
          )
        })
        .finally((this.partnerName = partnerOur))
    } catch (e) {
      if (
        !e.message.includes('Unexpected token') ||
        !e?.includes('Unexpected token') ||
        !e.message.includes('Unexpected identifier')
      ) {
        this.$swal.fire({
          icon: 'error',
          title: e.message,
        })
      }
    }

    await this.addLogPage()
    await this.getBankList()
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            name: 'สมาชิก',
            url: window.location.href,
            detail: 'แก้ไขสมาชิก',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getBankList(){
      let bankList;
      let bankCountry = this.bankSystem[0] || 'TH';
      this.bankCountry = bankCountry;
      switch(bankCountry) {
        case 'LA':
          bankList = LABanks
          break;
        case 'KR':
          bankList = KRBanks
          break;
        case 'KH':
          bankList = KHBanks
          break;
        default:
          bankList = this.THBanks
        break;
      }
      const formattedBanks = bankList.map(bank => ({
        label: bankCountry === 'TH' ? bank.label :`${bank.bank_name} (${bank.bank_name_en})`,
        value: bankCountry === 'TH' ? bank.value : bank.bank_code,
      }));
      this.bankItems = formattedBanks
    },
    setDefaultData(result) {
      this.defaultTurnoverList = JSON.parse(JSON.stringify(result))
      this.turnOver = result
      this.affiliateTurnoverList = result?.data?.affiliate
      this.cashbackTurnoverList = result?.data?.cashback
      this.couponTurnoverList = result?.data?.coupon
      this.promotionTurnoverList = result?.data?.promotion
      this.activityTurnoverList = result?.data?.activity

      this.turnoverToDoBefore = this.turnOver.total_turnover_todo
      this.turnoverDoneBefore = this.turnOver.total_turnover_done
      this.turnoverToDoAfter = this.turnOver.total_turnover_todo
      this.turnoverDoneAfter = this.turnOver.total_turnover_done
      this.turnoverResult = this.turnOver.remaining_turnover
      this.datas.turnOver = this.turnOver.remaining_turnover
      this.turnoverUpdate = 0
    },
    async save() {
      this.loading = true
      if (this.$refs.form.validate()) {
        let params = {
          id: this.datas.id,
          name: this.datas.name,
          bank: this.datas.bank,
          bank_name: this.datas.bank_name,
          bank_account: this.datas.bank_account,
          bank_code: this.datas.bank_code,
          type: this.datas.partner === true ? 'partner' : 'member',
          commission_percent: parseFloat(this.datas.commission_percent),
          line: this.datas.line,
          email: this.datas.email,
          cashback: this.datas.cashback,
          ...(this.partnerName && this.partnerName !== 0 ? { partner: this.partnerName } : { partner: null }),
          // create_membergame: true,
          ...(this.datas.password ? { password: this.datas.password } : {}),
          confirm_password: this.datas.password,
          ...(this.countryCode ? { country_code: this.countryCode } : {}),
        }

        try {
          await this.$store.dispatch('updateMember', params)
          this.$router.push({ name: 'user' })
        } catch (e) {
          if (
            !e.message.includes('Unexpected token') ||
            !e?.includes('Unexpected token') ||
            !e.message.includes('Unexpected identifier')
          ) {
            this.$swal.fire({
              icon: 'error',
              title: e.message,
            })
          }
        }
      }
      this.loading = false
    },
    validateReset() {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t('alert'),
          text: this.$t('user.form_reset'),
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
        })
        .then(async result => {
          if (result.isConfirmed) {
            this.reset()
          }
        })
    },
    async reset() {
      this.loading = true
      try {
        let res = await this.$store.dispatch('ClearTurnOver', this.$route.params.id)
        if (res) {
          this.$swal.fire(this.$t('user.form_reset_complete'), '', 'success')
        }
        this.$store.dispatch('getMemberTurnOver', this.$route.params.id).then(result => {
          this.setDefaultData(result)
        })
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      this.loading = false
    },
    validateCustomReset(data) {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t('alert'),
          text: this.$t('user.form_reset_this_turn'),
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
        })
        .then(async result => {
          if (result.isConfirmed) {
            this.customReset(data)
          }
        })
    },
    async customReset(data) {
      this.loading = true
      try {
        let params = {
          id: this.$route.params.id,
          data,
        }
        let res = await this.$store.dispatch('customClearTurnOver', params)
        if (res) {
          this.$swal.fire(this.$t('user.form_reset_complete'), '', 'success')
        }
        this.$store.dispatch('getMemberTurnOver', this.$route.params.id).then(result => {
          this.setDefaultData(result)
        })
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      this.loading = false
    },
    validateCustomUpdateTurnover() {
      if (this.totalErrors > 0) {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: this.$t('user.form_validate_custom_update_turnover'),
        })
      } else {
        let dataChange = {}

        for (const type in this.defaultTurnoverList.data) {
          if (Object.hasOwnProperty.call(this.defaultTurnoverList.data, type)) {
            const defaultItems = this.defaultTurnoverList.data[type]
            const updateItems = this.turnOver.data[type]

            if (!Array.isArray(defaultItems) || !Array.isArray(updateItems)) {
              continue // Skip if the data is not an array
            }

            const changedItems = []

            // Iterate over each item in the type
            for (let i = 0; i < defaultItems.length; i++) {
              const defaultItem = defaultItems[i]
              const updateItem = updateItems[i]

              // Compare the turnToDo and turnDone values
              if (
                (defaultItem.turnToDo !== updateItem.turnToDo && defaultItem.turnDone !== updateItem.turnDone) ||
                defaultItem.turnToDo !== updateItem.turnToDo ||
                defaultItem.turnDone !== updateItem.turnDone
              ) {
                changedItems.push(updateItem)
              }
            }

            // Add the changed items to datachange[type]
            this.$set(dataChange, type, changedItems)
          }
        }

        this.customUpdateTurnover(dataChange)
      }
    },
    async customUpdateTurnover(data) {
      this.loading = true
      try {
        let params = {
          id: this.$route.params.id,
          data,
        }
        let res = await this.$store.dispatch('customUpdateTurnOver', params)
        if (res) {
          this.$swal.fire(this.$t('user.form_update_complete'), '', 'success')
        }
        this.$store.dispatch('getMemberTurnOver', this.$route.params.id).then(result => {
          this.setDefaultData(result)
        })
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      this.loading = false
    },
    updateTurnoverValue(value, index) {
      let inputValue = parseFloat(value.label === 'turnToDo' ? value.turnToDo : value.turnDone)
      let dataList = []

      switch (value.type) {
        case 'coupon':
          dataList = this.couponTurnoverList
          break
        case 'promotion':
          dataList = this.promotionTurnoverList
          break
        case 'affiliate':
          dataList = this.affiliateTurnoverList
          break
        case 'cashback':
          dataList = this.cashbackTurnoverList
          break
        case 'activity':
          dataList = this.activityTurnoverList
          break
        default:
          break
      }

      dataList[index][value.label] = inputValue
      if (isNaN(inputValue)) {
        dataList[index][value.label] = null
      }
      if (inputValue === '') {
        dataList[index][value.label] = 0
      }

      this.calculateTurnover()
    },
    calculateTurnover() {
      let totalDefaultValue = 0
      let totalUpdateValue = 0

      let totalTurnToDo = 0
      let totalTurnDone = 0

      for (const type in this.turnOver.data) {
        if (Object.hasOwnProperty.call(this.turnOver.data, type)) {
          let items = this.turnOver.data[type]
          for (let item of items) {
            let turnToDo = item.turnToDo || 0
            let turnDone = item.turnDone || 0

            totalTurnToDo += turnToDo
            totalTurnDone += turnDone
          }
        }
      }

      this.turnoverToDoAfter = parseFloat(totalTurnToDo.toFixed(2))
      this.turnoverDoneAfter = parseFloat(totalTurnDone.toFixed(2))
      this.turnoverUpdate = parseFloat(
        (
          parseFloat((this.turnoverDoneBefore - this.turnoverToDoBefore).toFixed(2)) -
          parseFloat((this.turnoverDoneAfter - this.turnoverToDoAfter).toFixed(2))
        ).toFixed(2),
      )
      this.turnoverResult = parseFloat((this.turnoverToDoAfter - this.turnoverDoneAfter).toFixed(2))
    },
    generateErrorMessages(i, label) {
      const errorMessages = []

      if (label === 'turnToDo') {
        if (i.turnToDo === null || isNaN(i.turnToDo) || i.turnToDo < 0) {
          errorMessages.push(this.$t('user.form_error_message1'))
        }

        if (i.turnToDo === 0 && i.turnToDo < i.turnDone) {
          errorMessages.push(this.$t('user.form_error_message2'))
        }

        if (i.turnToDo > 0 && i.turnToDo < i.turnDone) {
          errorMessages.push(`${this.$t('user.form_error_message3')} ${i.turnDone || 0}`)
        }
      }

      if (label === 'turnDone') {
        if (i.turnDone === null || isNaN(i.turnDone) || i.turnDone < 0) {
          errorMessages.push(this.$t('user.form_error_message1'))
        }

        if (i.turnToDo === 0 && i.turnToDo < i.turnDone) {
          errorMessages.push(this.$t('user.form_error_message4'))
        }

        if (i.turnToDo > 0 && i.turnToDo < i.turnDone) {
          errorMessages.push(`${this.$t('user.form_error_message3')} ${i.turnToDo || 0}`)
        }
      }

      return errorMessages
    },
    resetDefaultValue(data, index) {
      let dataList = []
      let type = ''
      switch (data.type) {
        case 'coupon':
          dataList = this.couponTurnoverList
          type = 'coupon'
          break
        case 'promotion':
          dataList = this.promotionTurnoverList
          type = 'promotion'
          break
        case 'affiliate':
          dataList = this.affiliateTurnoverList
          type = 'affiliate'
          break
        case 'cashback':
          dataList = this.cashbackTurnoverList
          type = 'cashback'
          break
        case 'activity':
          dataList = this.activityTurnoverList
          type = 'activity'
          break
        default:
          break
      }
      const defaultValue = this.defaultTurnoverList.data[type][index][data.label]
      this.$set(dataList[index], data.label, defaultValue)
      this.calculateTurnover()
    },
    filter: function (evt) {
      evt = evt ? evt : window.event
      let expect = evt.target.value.toString() + evt.key.toString()

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
  },
  computed: {
    totalErrors() {
      let total = 0

      for (const type in this.turnOver.data) {
        if (Object.hasOwnProperty.call(this.turnOver.data, type)) {
          let items = this.turnOver.data[type]
          for (let item of items) {
            let turnToDoErrors = this.generateErrorMessages(item, 'turnToDo')
            let turnDoneErrors = this.generateErrorMessages(item, 'turnDone')

            total += turnToDoErrors.length + turnDoneErrors.length
          }
        }
      }
      return total
    },
    disabledUpdateTurnoverButton() {
      let total = 0

      for (const type in this.defaultTurnoverList.data) {
        if (Object.hasOwnProperty.call(this.defaultTurnoverList.data, type)) {
          const defaultItems = this.defaultTurnoverList.data[type]
          const updateItems = this.turnOver.data[type]

          if (!Array.isArray(defaultItems) || !Array.isArray(updateItems)) {
            continue // Skip if the data is not an array
          }

          const changedItems = []

          // Iterate over each item in the type
          for (let i = 0; i < defaultItems.length; i++) {
            const defaultItem = defaultItems[i]
            const updateItem = updateItems[i]

            // Compare the turnToDo and turnDone values
            if (
              (defaultItem.turnToDo !== updateItem.turnToDo && defaultItem.turnDone !== updateItem.turnDone) ||
              defaultItem.turnToDo !== updateItem.turnToDo ||
              defaultItem.turnDone !== updateItem.turnDone
            ) {
              total += 1
            }
          }
        }
      }
      return total > 0 ? false : true
    },
  },
}
</script>
<style lang="css" scoped>
.th-col {
  border-top: 0 !important;
}

.th-col:first-child {
  border-radius: 1rem 0 0 0;
}

.th-col:last-child {
  border-radius: 0 1rem 0 0;
}

@media (max-width: 550px) {
  .form-user-edit {
    display: flex;
    flex-direction: column;
  }

  .form-user-edit button {
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: 8px !important;
  }
}

.btn-custom {
  background: #7367f0 !important;
  color: white !important;
}
</style>
