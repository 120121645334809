var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("staff.staff_head"))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("staff.staff_list"))+" ")]),_c('v-card-actions',{staticClass:"pb-0 d-flex align-start"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('span',[_c('v-select',{attrs:{"label":_vm.$t('staff.staff_status'),"items":_vm.roles,"item-text":"label","outlined":"","dense":""},model:{value:(_vm.role_id),callback:function ($$v) {_vm.role_id=$$v},expression:"role_id"}})],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.searchStaff}},[_vm._v(" "+_vm._s(_vm.$t("search"))+" ")])],1)],1),_c('br'),_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.linkToAdd}},[_vm._v(_vm._s(_vm.$t("staff.staff_create"))+" ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.roleDialog = true}}},[_vm._v("role")]),_c('v-spacer'),_c('span',{staticClass:"px-3 mt-5"},[_vm._v(_vm._s(_vm.$t("search"))+" :")]),_c('div',{staticClass:"mt-5",staticStyle:{"width":"150px"}},[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:500ms",value:(_vm.searchKeyword),expression:"searchKeyword",arg:"500ms"}],attrs:{"date":_vm.searchData,"outlined":"","dense":"","hide-details":""}})],1)],1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"no-data-text":_vm.$t('not_found'),"no-results-text":_vm.$t('not_found'),"options":_vm.pagination,"server-items-length":_vm.pagination.totalItems,"items-per-page":_vm.pagination.rowsPerPage,"page":_vm.pagination.page,"loading":_vm.loading,"footer-props":{
            'items-per-page-options': [10, 25, 30, 40, 50],
          },"items":_vm.members},on:{"update:options":function($event){_vm.pagination=$event},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)}},scopedSlots:_vm._u([{key:"item.bank",fn:function(ref){
          var item = ref.item;
return [(item.bank)?_c('img',{attrs:{"alt":"Avatar","width":"25px","src":require(("@/assets/images/logos/banks/" + (item.bank) + ".png"))}}):_vm._e()]}},{key:"item.username",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.showMemberDetail(item)}}},[_vm._v(" "+_vm._s(item.username)+" ")])]}},{key:"item.status_2fa",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"color":item.status_2fa === 1 ? 'success' : 'error'},on:{"click":function($event){return _vm.update2FAStatus(item)}}},[_vm._v(" "+_vm._s(item.status_2fa === 1 ? '2FA Enabled' : '2FA Disabled')+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-square-edit-outline")]),_c('span',{staticClass:"px-3 white--text"},[_vm._v(_vm._s(_vm.$t("staff.manage"))+" ")]),_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.linkToEdit(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v("mdi-square-edit-outline")]),_c('span',{staticClass:"px-4"},[_vm._v(_vm._s(_vm.$t("staff.staff_edit")))])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.deleteStaff(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-delete")]),_c('span',{staticClass:"px-4"},[_vm._v(_vm._s(_vm.$t("staff.staff_delete")))])],1)],1)],1)],1)]}}],null,true)})],1)],1),_c('staff-show-detail',{attrs:{"dialog":_vm.showDetail,"username":_vm.currentMemberDetail.username,"password":_vm.currentMemberDetail.password},on:{"setDialog":function($event){_vm.showDetail = $event}}}),_c('create-role',{attrs:{"dialog":_vm.roleDialog},on:{"setDialog":function($event){_vm.roleDialog = $event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }