<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('web_setting.head_list10') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="6">
              <v-text-field v-model="bathCoin" :label="getCurrencyI18n($t('web_setting.wheel_input1'))" :rules="rules"
                outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="6">
              <v-text-field v-model="coinUse" :label="$t('web_setting.wheel_input2')" :rules="rules" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-divider class="mx-3" />
        <v-card-actions v-for="(i, n) in form" :key="n">
          <v-row>
            <v-col cols="6" xs="6" md="6" lg="3">
              <v-text-field v-model="i.name" :label="$t('web_setting.wheel_input3')" outlined dense />
            </v-col>
            <v-col cols="6" xs="6" md="6" lg="3">
              <v-text-field v-model="i.percent" :label="$t('web_setting.wheel_input4')" :rules="rules" outlined dense />
            </v-col>
            <v-col cols="6" xs="6" md="6" lg="3">
              <v-text-field v-model="i.credit" :label="$t('web_setting.wheel_input5')" :rules="rules" outlined dense />
            </v-col>
            <v-col cols="6" xs="6" md="6" lg="3">
              <color-picker-input :index="n" :label="$t('web_setting.wheel_input6')" :colorHexValue="i.color"
                @setColor="i.color = $event" />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ColorPickerInput from '@/components/input/ColorPickerHexInput.vue'
import { getCurrency, getCurrencyI18n } from '@/utils/currencyUtils'

export default {
  components: {
    ColorPickerInput,
  },
  props: {
    wheelBathCoin: {
      type: String,
      default: ''
    },
    wheelCoinUse: {
      type: String,
      default: ''
    },
    info: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      rules: [
        value => /^\d+$/.test(value) || this.$t('alert_list.list2'),
      ],
      bankSystem: ''
    }
  },
  computed: {
    bathCoin: {
      get() {
        return this.wheelBathCoin
      },
      set(value) {
        this.$emit('setWheelBathCoin', value)

      }
    },
    coinUse: {
      get() {
        return this.wheelCoinUse
      },
      set(value) {
        this.$emit('setWheelCoinUse', value)
      }
    },
    form: {
      get() {
        return this.info
      },
      set(value) {
        this.$emit('setValue', value)
      }
    }
  },
  async created() {
    this.getWebSettingInfo()
  },
  methods: {
    async getWebSettingInfo() {
      try {
        let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
        this.bankSystem = settingWebInfo?.bank_system[0] || 'TH';
      } catch (e) { }
    },
    setColor() { },
    getCurrency() {
      return getCurrency(this.bankSystem);
    },
    getCurrencyI18n(message) {
      return getCurrencyI18n(this.bankSystem, message);
    },
  }
}
</script>