<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-col class="d-flex flex-row px-6">
          <v-btn-toggle class="pt-2">
            <v-btn @click="changeTab('lotto')" :color="tab === 'lotto' ? 'primary' : 'none'">Lotto</v-btn>
            <v-btn @click="changeTab('sport')" :color="tab === 'sport' ? 'primary' : 'none'">Sport</v-btn>
            <v-btn @click="changeTab('casino')" :color="tab === 'casino' ? 'primary' : 'none'">Casino</v-btn>
            <v-btn @click="changeTab('slot')" :color="tab === 'slot' ? 'primary' : 'none'">Slot</v-btn>
          </v-btn-toggle>
        </v-col>

        <div style="padding: 1.5rem 0 3rem 0">
          <v-row class="ma-0 px-5">
            <v-col class="col col-12 col-sm-6 col-md-3" v-for="(item, index_1) of gameForm" :key="index_1">
              <v-card class="pa-2 pb-4" style="aspect-ratio: 1/0.55" outlined>
                <v-card-title class="px-3 py-0">
                  <v-col class="pa-0 pb-2">
                    <div class="game-icon">
                      <img alt="game-logo" :src="require(`@/assets/images/logos/games/${item.code}.png`)" />
                    </div>
                    <v-row class="ma-0 pt-1 d-flex align-center">
                      {{ item.name }}
                      <v-tooltip right v-if="(!item.enable && item.maintenance) || !item.enable || item.maintenance">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            class="ps-0 warning-icon"
                            :style="{
                              color: item.maintenance ? 'var(--v-error-base)' : !item.enable ? 'var(--v-warning-base)' : null,
                              fontSize: 'min(4vw, 1rem)'
                            }">
                            mdi-alert-circle
                          </v-icon>
                        </template>
                        <span>{{
                            !item.enable && item.maintenance
                            ? $t('web_setting.tooltip_red2')
                            : item.maintenance
                            ? $t('web_setting.tooltip_red1')
                            : !item.disable
                            ? $t('web_setting.tooltip_yellow')
                            : ''
                          }}</span>
                      </v-tooltip>
                    </v-row>
                  </v-col>

                </v-card-title>
                <v-row class="ma-0">
                  <v-col class="col-6 py-0">
                    <v-card-subtitle class="ma-0 pa-0">{{ $t('web_setting.status2') }}</v-card-subtitle>
                    <v-switch
                      v-model="item.enable"
                      @change="changeStatus(item)"
                      color="success"
                      class="ma-0 pa-0"
                      hide-details
                      inset
                    >
                    </v-switch>
                  </v-col>
                  <v-col class="col-6 py-0">
                    <v-card-subtitle class="ma-0 pa-0">{{ $t('web_setting.maintenance') }}</v-card-subtitle>
                    <v-switch
                      v-model="item.maintenance"
                      @change="changeStatus(item)"
                      color="error"
                      class="ma-0 pa-0"
                      hide-details
                      inset
                    >
                    </v-switch>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>

export default {
  props: {
    gameInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      tab: 'lotto',
    }
  },
  async created() {
  },
  methods: {
    changeTab(tab) {
      this.tab = tab
    },
    changeStatus(data) {
      this.$emit('updateGame', data);
    },
  },
  computed: {
    gameForm: {
      get() {
        return this.gameInfo[this.tab]
      },
      set(value) {
        this.$emit('setGame', value)
      },
    },
  },
}
</script>
<style scoped>
.v-input__control {
  margin-left: 0;
}

.v-card__title {
  font-size: min(4vw, 1rem);
}
.v-card__subtitle {
  font-size: min(3.5vw, 0.8rem);
}

.warning-icon {
  animation: icon-anim 2s infinite;
  -webkit-animation: icon-anim 1s infinite;
  aspect-ratio: 1/1;
  cursor: pointer;
}

.game-icon img {
  width: 100%;
  height: 100%;
  max-width: 36px;
  max-height: 36px;
  aspect-ratio: 1/1;
}
@keyframes icon-anim {
  from {
    transform: scale(0.8);
  }
  90% {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
  }
}
@-webkit-keyframes icon-anim {
  from {
    -webkit-transform: scale(0.8);
  }
  90% {
    -webkit-transform: scale(1);
  }
  to {
    -webkit-transform: scale(0.8);
  }
}
</style>
