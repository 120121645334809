<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('promotion.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-divider class="mx-3" vertical />

    <!-- promotion type
          - normal                        | ปกติ/โบนัส
          - normal_min_deposit            | ฝากขั้นต่ำ
          - normal_min_deposit_fix_bonus  | ฝากขั้นต่ำ + fix bonus
          - fix_deposit_bonus             | fix ยอดฝาก bonus
    -->

    <!-- new patch -->
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('promotion.head_list2') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-form ref="formPromotion" class="mt-5">
          <div v-if="!form.format">
            <v-card-actions>
              <v-row>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-select v-model="form.format" :label="$t('promotion.header1')" :items="formItems" outlined dense
                    :rules="formRule.required" />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-select v-model="form.type" :label="$t('promotion.head_list2')" :items="typeItems" outlined dense
                    :rules="formRule.required" />
                </v-col>
              </v-row>
            </v-card-actions>
          </div>

          <div v-if="form.format === 'normal'">
            <v-card-actions>
              <v-row>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-select v-model="form.format" :label="$t('promotion.header1')" :items="formItems" outlined dense
                    :rules="formRule.required" />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-select v-model="form.type" :label="$t('promotion.head_list2')" :items="typeItems" outlined dense
                    :rules="formRule.required" />
                </v-col>
              </v-row>
            </v-card-actions>
            <v-card-actions>
              <v-row>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.name" outlined dense :label="$t('promotion.header3')"
                    :rules="formRule.required" />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.percent" :label="$t('promotion.header6_2')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.max_amount" :label="$t('promotion.header7_2')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.multiply_turnover" :label="$t('promotion.header8')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.max_per_user" :label="$t('promotion.header4')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-select v-model="form.enable" :label="$t('promotion.header9')" :items="statusItems" outlined dense
                    :rules="formRule.required" />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <div class="upload-container">
                    <input class="upload-banner" v-model="filename" type="text" placeholder="รูปภาพ"
                      messages="รูปภาพ (700x200)" readonly />
                    <button class="clear-btn" v-if="filename" @click="clearValue('upload')">X</button>
                    <input type="file" @change="uploadFile('upload')" accept="image/*" placeholder="อัพโหลดรูปโลโก้"
                      ref="upload" style="width: 200px; display: none" />
                    <v-btn text color="primary" class="btn-custom" @click="$refs.upload.click()">อัพโหลด</v-btn>
                    <label style="font-size: 0.8rem">(700x200)</label>
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
          </div>

          <div v-if="form.format === 'normal_min_deposit'">
            <v-card-actions>
              <v-row>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-select v-model="form.format" :label="$t('promotion.header1')" :items="formItems" outlined dense
                    :rules="formRule.required" />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-select v-model="form.type" :label="$t('promotion.head_list2')" :items="typeItems" outlined dense
                    :rules="formRule.required" />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.percent" :label="$t('promotion.header6_2')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
              </v-row>
            </v-card-actions>
            <v-card-actions>
              <v-row>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.name" outlined dense :label="$t('promotion.header3')"
                    :rules="formRule.required" />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.min_deposit" :label="$t('promotion.header5')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.max_amount" :label="$t('promotion.header7_2')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.multiply_turnover" :label="$t('promotion.header8')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.max_per_user" :label="$t('promotion.header4')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-select v-model="form.enable" :label="$t('promotion.header9')" :items="statusItems" outlined dense
                    :rules="formRule.required" />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <div class="upload-container">
                    <input class="upload-banner" v-model="filename" type="text" placeholder="รูปภาพ"
                      messages="รูปภาพ (700x200)" readonly />
                    <button class="clear-btn" v-if="filename" @click="clearValue('upload')">X</button>
                    <input type="file" @change="uploadFile('upload')" accept="image/*" placeholder="อัพโหลดรูปโลโก้"
                      ref="upload" style="width: 200px; display: none" />
                    <v-btn text color="primary" class="btn-custom" @click="$refs.upload.click()">อัพโหลด</v-btn>
                    <label style="font-size: 0.8rem">(700x200)</label>
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
          </div>

          <div v-if="form.format === 'normal_min_deposit_fix_bonus'">
            <v-card-actions>
              <v-row>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-select v-model="form.format" :label="$t('promotion.header1')" :items="formItems" outlined dense
                    :rules="formRule.required" />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-select v-model="form.type" :label="$t('promotion.head_list2')" :items="typeItems" outlined dense
                    :rules="formRule.required" />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.name" outlined dense :label="$t('promotion.header3')"
                    :rules="formRule.required" />
                </v-col>
              </v-row>
            </v-card-actions>
            <v-card-actions>
              <v-row>
                <v-col cols="12" xs="12" md="6" lg="3">
                  <v-text-field v-model="form.min_deposit" :label="$t('promotion.header5')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="3">
                  <v-text-field v-model="form.fix_deposit_bonus" :label="$t('promotion.header6_3')" outlined dense
                    :disabled="isPercentPositive" :rules="formPercentRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="3">
                  <v-text-field v-model="form.percent" :label="$t('promotion.header6_4')" outlined dense
                    :disabled="isFixDepositBonusPositive" :rules="formFixDepositBonusRule.number" type="number"
                    hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="3">
                  <v-text-field v-model="form.max_amount" :label="$t('promotion.header7_2')" outlined dense
                    :disabled="isFixDepositBonusPositive" :rules="formFixDepositBonusRule.number" type="number"
                    hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.multiply_turnover" :label="$t('promotion.header8')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.max_per_user" :label="$t('promotion.header4')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-select v-model="form.enable" :label="$t('promotion.header9')" :items="statusItems" outlined dense
                    :rules="formRule.required" />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <div class="upload-container">
                    <input class="upload-banner" v-model="filename" type="text" placeholder="รูปภาพ"
                      messages="รูปภาพ (700x200)" readonly />
                    <button class="clear-btn" v-if="filename" @click="clearValue('upload')">X</button>
                    <input type="file" @change="uploadFile('upload')" accept="image/*" placeholder="อัพโหลดรูปโลโก้"
                      ref="upload" style="width: 200px; display: none" />
                    <v-btn text color="primary" class="btn-custom" @click="$refs.upload.click()">อัพโหลด</v-btn>
                    <label style="font-size: 0.8rem">(700x200)</label>
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
          </div>

          <div v-if="form.format === 'fix_deposit_bonus'">
            <v-card-actions>
              <v-row>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-select v-model="form.format" :label="$t('promotion.header1')" :items="formItems" outlined dense
                    :rules="formRule.required" />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-select v-model="form.type" :label="$t('promotion.head_list2')" :items="typeItems" outlined dense
                    :rules="formRule.required" />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.name" outlined dense :label="$t('promotion.header3')"
                    :rules="formRule.required" />
                </v-col>
              </v-row>
            </v-card-actions>
            <v-card-actions>
              <v-row>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.fix_deposit_bonus" :label="$t('promotion.header7_3')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.percent" :label="$t('promotion.header11')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.multiply_turnover" :label="$t('promotion.header8')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-text-field v-model="form.max_per_user" :label="$t('promotion.header4')" outlined dense
                    :rules="formRule.number" type="number" hide-spin-buttons />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <v-select v-model="form.enable" :label="$t('promotion.header9')" :items="statusItems" outlined dense
                    :rules="formRule.required" />
                </v-col>
                <v-col cols="12" xs="12" md="6" lg="4">
                  <div class="upload-container">
                    <input class="upload-banner" v-model="filename" type="text" placeholder="รูปภาพ"
                      messages="รูปภาพ (700x200)" readonly />
                    <button class="clear-btn" v-if="filename" @click="clearValue('upload')">X</button>
                    <input type="file" @change="uploadFile('upload')" accept="image/*" placeholder="อัพโหลดรูปโลโก้"
                      ref="upload" style="width: 200px; display: none" />
                    <v-btn text color="primary" class="btn-custom" @click="$refs.upload.click()">อัพโหลด</v-btn>
                    <label style="font-size: 0.8rem">(700x200)</label>
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
          </div>
        </v-form>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-spacer />
          <v-btn color="warning" @click="$router.push({ name: 'promotion' })">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="px-3">ย้อนกลับ</span>
          </v-btn>
          <v-btn color="success" @click="save" class="px-3" :loading="loading">
            <v-icon>mdi-content-save</v-icon>
            <span class="px-3">บันทึก</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import store from '@/store'

export default {
  data() {
    return {
      loading: false,
      breadcrumbsItems: [
        {
          text: this.$t('promotion.head'),
          disabled: false,
          href: '/promotion',
        },
        {
          text: this.$t('promotion.create'),
          disabled: true,
          href: '',
        },
      ],
      formRule: {
        required: [value => !!value || 'This field is required.'],
        number: [
          value => !!value || 'number is required.',
          // value => (!!value && value > 0) || 'need more than 0'
        ],
      },
      formPercentRule: {
        number: [
          value => (!!value || value === 0) || 'number is required.',
          value => value >= 0 || 'must be positive',
        ]
      },
      formFixDepositBonusRule: {
        number: [
          value => (!!value || value === 0) || 'number is required.',
          value => value >= 0 || 'must be positive',
        ]
      },
      formItems: [
        {
          text: this.$t('promotion.form_item1'),
          value: 'normal',
        },
        {
          text: this.$t('promotion.form_item2'),
          value: 'normal_min_deposit',
        },
        {
          text: this.$t('promotion.form_item3'),
          value: 'normal_min_deposit_fix_bonus',
        },
        {
          text: this.$t('promotion.form_item4'),
          value: 'fix_deposit_bonus',
        },
      ],
      typeItems: [
        {
          text: this.$t('promotion.type_item1'),
          value: 'forever',
        },
        {
          text: this.$t('promotion.type_item2'),
          value: 'daily',
        },
        {
          text: this.$t('promotion.type_item3'),
          value: 'weekly',
        },
        {
          text: this.$t('promotion.type_item4'),
          value: 'monthly',
        },
      ],
      statusItems: [
        {
          text: this.$t('open'),
          value: true,
        },
        {
          text: this.$t('close'),
          value: false,
        },
      ],
      form: {
        type: '',
        format: '',
        name: '',
        multiply_turnover: 0,
        fix_deposit_bonus: 0,
        percent: 0,
        max_amount: 0,
        max_per_user: 0,
        min_deposit: 0,
        enable: false,
        image: null,
      },
      filename: '',
    }
  },
  async created() {
    this.addLogPage()
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            name: 'โปรโมชั่น',
            url: window.location.href,
            detail: 'เพิ่มโปรโมชั่น',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async save() {
      if (this.form.fix_deposit_bonus === '') {
        this.form.fix_deposit_bonus = 0;
      }

      if (this.form.percent === '') {
        this.form.percent = 0;
      }

      if (this.form.max_amount === '') {
        this.form.max_amount = 0;
      }

      if (this.form.format === 'normal_min_deposit_fix_bonus') {
        if (parseInt(this.form.fix_deposit_bonus) > 0) {
          this.form.percent = 0;
          this.form.max_amount = 0;
        }

        if (parseInt(this.form.percent) > 0 || parseInt(this.form.max_amount) > 0) {
          this.form.fix_deposit_bonus = 0;
        }
      }

      if (this.$refs.formPromotion.validate()) {
        this.loading = true
        try {
          let max_amount = 0;
          if (this.form.format === 'fix_deposit_bonus') {
            max_amount = parseInt(this.form.percent)
          } else if (this.form.format === 'normal_min_deposit_fix_bonus') {
            if (parseInt(this.form.percent) > 0) {
              max_amount = parseInt(this.form.max_amount)
            } else {
              max_amount = parseInt(this.form.percent)
            }
          } else {
            max_amount = parseInt(this.form.max_amount)
          }

          const data = {
            type: this.form.type,
            format: this.form.format,
            name: this.form.name,
            multiply_turnover: parseInt(this.form.multiply_turnover),
            percent: parseInt(this.form.percent),
            fix_deposit_bonus: parseInt(this.form.fix_deposit_bonus),
            max_amount: max_amount,
            max_per_user: parseInt(this.form.max_per_user),
            min_deposit: parseInt(this.form.min_deposit),
            enable: this.form.enable,
            image: this.form.image,
          }

          await this.$store.dispatch('addPromotion', data)
          this.$swal.fire(this.$t('promotion.alert1'), '', 'success')
          this.$router.push({ name: 'promotion' })
        } catch (e) {
          if (
            !e.message.includes('Unexpected token') ||
            !e?.includes('Unexpected token') ||
            !e.message.includes('Unexpected identifier')
          ) {
            this.$swal.fire({
              icon: 'error',
              title: e.message && e.message.length > 1 ? e.message : e.message[0],
            })
          }
        }
        this.loading = false
      }
    },

    async uploadFile(type) {
      this.$swal.fire({
        html: this.$t('processing'),
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })

      let dataFile
      dataFile = this.$refs.upload.files[0]

      var bodyFormData = new FormData()
      bodyFormData.append('name', type)
      bodyFormData.append('file', dataFile)
      let dataRes

      dataRes = await this.$store.dispatch('putSettingUpload', bodyFormData)
      // console.log(dataRes, dataRes.data.file_url);

      if (dataRes) {
        this.$swal.fire(this.$t('upload_complete'), '', 'success')
        this.filename = dataRes.data.file_url ? dataRes.data.file_url : ''
        this.form.image = dataRes.data.file_url ? dataRes.data.file_url : ''
      } else {
        this.$swal.fire(this.$t('upload_fail'), '', 'error')
      }
    },
    clearValue(val) {
      this.form.image = ''
      this.filename = ''
    },
  },
  computed: {
    isFixDepositBonusPositive() {
      return this.form.fix_deposit_bonus > 0;
    },
    isPercentPositive() {
      return this.form.percent > 0 || this.form.max_amount > 0;
    },
  },
  watch: {
    'form.format': function () {
      this.form.percent = 0;
      this.form.max_amount = 0;
    },
  }
}
</script>
<style scoped>
.btn-custom {
  background: #7367f0;
  color: white !important;
}

.upload-container {
  width: 100%;
}

.upload-container input {
  height: 39px;
  width: 77%;
  border: 1px solid rgb(194, 194, 194);
  border-radius: 5px;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(138, 138, 138);
}

.upload-container input::placeholder {
  color: rgb(139, 139, 139);
  font-size: 13px;
}

.upload-banner:hover+.clear-btn {
  display: block !important;
}

.clear-btn {
  display: none;
  height: 25px;
  width: 25px;
  z-index: 5;
  position: absolute;
  border: 1px solid gray;
  border-radius: 50%;
  background: white;
  margin: -33px 0 0 -15px;
}

.clear-btn:hover {
  display: block !important;
}

/* btn-custom */
@media (max-width: 550px) {
  .upload-container input {
    width: 73%;
  }

  .btn-custom {
    width: 80px !important;
    min-width: 80px !important;
  }

  .btn-upload-logo {
    margin-top: -50px !important;
  }
}
</style>
