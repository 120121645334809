<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <!-- <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img> -->

            <h2 class="text-2xl font-weight-semibold">{{ webname }}</h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <!-- <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Welcome to AKOKBET! 👋🏻</p>
          <p class="mb-2">Please sign-in to your account and start the adventure</p>
        </v-card-text> -->

        <!-- login form -->
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="username"
              outlined
              label="Username"
              placeholder="ex. akokbet007"
              hide-details
              class="mb-3"
              :rules="[value => !!value || 'Username is required.']"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              :rules="[value => !!value || 'Password is required.']"
              @click:append="isPasswordVisible = !isPasswordVisible"
              @keyup.enter="login"
            ></v-text-field>

            <!-- <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox label="Remember Me" hide-details class="me-3 mt-1"> </v-checkbox>


              <a href="javascript:void(0)" class="mt-1"> Forgot Password? </a>
            </div> -->

            <v-btn block color="primary" :loading="loading" class="mt-6" @click="login"> Login </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <!-- <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2"> New on our platform? </span>
          <router-link :to="{ name: 'pages-register' }"> Create an account </router-link>
        </v-card-text> -->

        <!-- divider -->
        <!-- <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text> -->

        <!-- social links -->
        <!-- <v-card-actions class="d-flex justify-center">
          <v-btn v-for="link in socialLink" :key="link.icon" icon class="ms-1">
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <!-- <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img> -->

    <!-- tree  -->
    <!-- <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img> -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { login2fa } from '@/services/apis/login'

export default {
  data: () => ({
    loading: false,
    webname: null,
  }),
  async created() {
    await this.$store.dispatch('webSettingInfo')
    this.webname = await this.$store.getters.getWebName
  },
  setup() {
    const isPasswordVisible = ref(false)
    const username = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      username,
      password,
      socialLink,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    async login() {
      this.loading = true

      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch('login', {
            username: this.username,
            password: this.password,
          })
          if (this.$store.getters.getToken) {
            await this.$store.dispatch('permissions')
            let psSystem = this.$store.getters.getPermissions
            if (psSystem.length > 0) {
              const dashboardPermission = psSystem.filter(menu => menu === 'dashboard')
              const redirectTo = dashboardPermission.length > 0 ? 'dashboard' : psSystem[0]
              this.$router.push({ path: `/${redirectTo}` })
            } else {
              this.$swal.fire(`คุณไม่มีสิทธิ์เข้าระบบ ADMIN`)
            }
          } else {
            this.$swal({
              title: '2FA',
              html: `
                <div>
                  <p>ใส่รหัส 6 หลัก</p>
                </div>
              `,
              input: 'text',
              inputAttributes: {
                inputmode: 'numeric',
                pattern: '[0-9]*',
                maxlength: 6,
              },
              inputValidator: value => {
                if (!value || isNaN(value)) {
                  return 'โปรดกรอกเฉพาะตัวเลขเท่านั้น'
                }
                if (value && value.length !== 6) {
                  return 'โปรดกรอกให้ครบ 6 หลัก'
                }
              },
              inputPlaceholder: 'ใส่รหัสที่นี่...',
              showCancelButton: true,
              confirmButtonText: 'ยืนยัน',
              cancelButtonText: 'ออก',
            }).then(async result => {
              if (result.isConfirmed) {
                this.login2fa(result)
              }
            })
          }
        } catch (err) {
          if (!err.message.includes('Unexpected token')) {
            this.$swal.fire(`${err.message}`)
          }
        }
      }
      this.loading = false
    },
    async login2fa(result) {
      const pin = result.value
      const sendData = {
        username: this.username,
        password: this.password,
        pin: pin,
      }
      try {
        await this.$store.dispatch('login2fa', sendData)

        if (this.$store.getters.getToken) {
          await this.$store.dispatch('permissions')
          let psSystem = this.$store.getters.getPermissions
          if (psSystem.length > 0) {
            const dashboardPermission = psSystem.filter(menu => menu === 'dashboard')
            const redirectTo = dashboardPermission.length > 0 ? 'dashboard' : psSystem[0]
            this.$router.push({ path: `/${redirectTo}` })
          } else {
            this.$swal.fire(`คุณไม่มีสิทธิ์เข้าระบบ ADMIN`)
          }
        }
      } catch (err) {
        if (!err.message.includes('Unexpected token')) {
          this.$swal.fire(`${err.message}`)
        }
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
