export const KRBanks = [
  { bank_code: '002', bank_name: '산업은행', bank_name_en: 'Korea Development Bank' },
  { bank_code: '003', bank_name: '기업은행', bank_name_en: 'Industrial Bank of Korea' },
  { bank_code: '004', bank_name: '국민은행', bank_name_en: 'Kookmin Bank' },
  { bank_code: '007', bank_name: '수협', bank_name_en: 'Suhyup Bank' },
  { bank_code: '011', bank_name: '농협', bank_name_en: 'Nonghyup Bank' },
  { bank_code: '020', bank_name: '우리은행', bank_name_en: 'Woori Bank' },
  { bank_code: '023', bank_name: 'SC제일은행', bank_name_en: 'Standard Chartered Korea' },
  { bank_code: '027', bank_name: '한국씨티은행', bank_name_en: 'Citibank Korea' },
  { bank_code: '031', bank_name: '대구은행', bank_name_en: 'Daegu Bank' },
  { bank_code: '032', bank_name: '부산은행', bank_name_en: 'Busan Bank' },
  { bank_code: '034', bank_name: '광주은행', bank_name_en: 'Gwangju Bank' },
  { bank_code: '035', bank_name: '제주은행', bank_name_en: 'Jeju Bank' },
  { bank_code: '037', bank_name: '전북은행', bank_name_en: 'Jeonbuk Bank' },
  { bank_code: '039', bank_name: '경남은행', bank_name_en: 'Gyeongnam Bank' },
  { bank_code: '045', bank_name: '새마을금고', bank_name_en: 'MG Saemaul Geumgo Bank' },
  { bank_code: '048', bank_name: '신협', bank_name_en: 'Credit Union Bank' },
  { bank_code: '071', bank_name: '우체국', bank_name_en: 'Korea Post' },
  { bank_code: '081', bank_name: '하나은행', bank_name_en: 'Hana Bank' },
  { bank_code: '088', bank_name: '신한은행', bank_name_en: 'Shinhan Bank' },
  { bank_code: '089', bank_name: '케이뱅크', bank_name_en: 'K Bank' },
  { bank_code: '090', bank_name: '카카오뱅크', bank_name_en: 'Kakao Bank' },
];
