<template>
  <v-row>
    <v-col cols="12">
      <h2>ระบบจัดการ Bonus Time Exp</h2>
    </v-col>
    <v-col cols="12">
      <v-form ref="form" v-model="formValid">
        <v-card>
          <v-card-title> เปิด-ปิด โบนัส </v-card-title>

          <div v-if="canCreate">
            <v-card-actions class="pb-0">
              <v-col cols="12" class="pa-0">
                <v-row class="ma-0">
                  <v-col cols="12" class="py-0">
                    <v-card-text class="px-0 py-0" style="color: var(--v-info-base)">
                      ตัวอย่าง: ได้รับ 100 exp x {{ datas.multiplyExp ? datas.multiplyExp : 1 }} =
                      {{ datas.multiplyExp ? 100 * datas.multiplyExp : (100 * 1) | currency }} Exp
                    </v-card-text>
                    <v-card-text class="px-0 py-0" style="color: var(--v-error-base)">
                      (Exp จะตัดทุกวันเวลาบ่าย 3 โมงเย็น (3:00PM))
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row class="ma-0 mt-2">
                  <v-col cols="12" class="py-0">
                    <v-card-text class="px-0 py-0"> ทำรายการ </v-card-text>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="datas.multiplyExp"
                      :rules="[digitsRule, multiplyRule]"
                      label="คูณ x เท่า"
                      dense
                      outlined
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <date-picker-input
                      label="วันที่(เริ่ม)"
                      :date="datas.startDate"
                      @setValue="datas.startDate = $event"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <date-picker-input
                      label="วันที่(สิ้นสุด)"
                      :date="datas.endDate"
                      @setValue="datas.endDate = $event"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-card-actions>

            <v-card-actions>
              <v-spacer />
              <v-btn color="success" :loading="loadingSubmit" @click="addBonusExp" class="white--text">
                <v-icon small class="px-2">mdi-content-save-outline</v-icon>
                สร้าง
              </v-btn>
            </v-card-actions>

            <v-divider class="px-3" />
          </div>

          <v-card-actions class="pt-4">
            <v-data-table
              mobile-breakpoint="0"
              style="width: 100%"
              :headers="headers"
              :items="value"
              :no-data-text="$t('not_found')"
              :no-results-text="$t('not_found')"
              :options.sync="pagination"
              :server-items-length="pagination.totalItems"
              :items-per-page="pagination.rowsPerPage"
              :page.sync="pagination.page"
              :footer-props="{
                'items-per-page-options': [10, 25, 30, 40, 50],
              }"
              :loading="loading"
              :search="search"
            >
              <template v-slot:[`item.action`]="{ item }">
                <div class="d-flex flex-row" style="gap: 0.5rem">
                  <v-btn
                    v-if="canCreate"
                    color="error"
                    class="white--text text-capitalize"
                    @click="deleteBonusExp(item.id)"
                  >
                    ลบรายการ
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      search: '',
      canCreate: false,
      dateFrom: moment().format('YY-MM-DD'),
      dateTo: moment().add(1, 'days').format('YY-MM-DD'),
      formValid: true,
      headers: [
        {
          text: 'ลำดับ',
          value: 'id',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'ตัวคูณ',
          value: 'multiplyExp',
          align: 'center',
          sortable: false,
        },
        {
          text: 'เวลาเริ่ม',
          value: 'startDate',
          sortable: false,
        },
        {
          text: 'เวลาสิ้นสุด',
          value: 'endDate',
          sortable: false,
        },
        {
          text: 'สร้างโดยแอดมิน',
          value: 'admin',
          sortable: false,
        },
      ],
      loading: false,
      loadingSubmit: false,
      dummy: true,
      pagination: {
        currentPage: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      value: [],
      datas: {
        multiplyExp: null,
        startDate: null,
        endDate: null,
      },
    }
  },

  async created() {
    this.datas.startDate = this.dateFrom
    this.datas.endDate = this.dateTo

    this.addLogPage()
    this.getPermissionsEdit()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true

        if (cont) {
          this.loading = true
          this.value = []
          await this.getBonusExpData(newValue.page, newValue.itemsPerPage, this.search)
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
            (data = {
              ip: res.data.ip,
              name: 'ตั้งค่า Bonus Exp',
              url: window.location.href,
              detail: 'ระบบจัดการ Bonus Exp',
              admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
            }),
        ),
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getPermissionsEdit() {
      let psSystem = store.getters.getPermissionsEdit
      let chkEdit = psSystem.filter(el => el === 'bonusExp')
      if (chkEdit && chkEdit?.length > 0) {
        this.canCreate = true
        this.headers = [
          ...this.headers,
          {
            text: 'Action',
            value: 'action',
            width: '95px',
            align: 'center',
            sortable: false,
          },
        ]
      }
    },
    async getBonusExpData(page, row, search) {
      this.value = []
      this.loading = true
      try {
        let res = await this.$store.dispatch('getBonusExp', {
          page: page,
          rows: row,
          ...(search ? { search: search } : {}),
        })
        this.pagination.totalItems = res.count
        this.value = res.data
      } catch (e) {}
      this.loading = false
    },
    async addBonusExp() {
      const start = this.formatDateYYYY(this.datas.startDate)
      const end = this.formatDateYYYY(this.datas.endDate)
      let isStartDateBeforeEndDate = moment(start).isBefore(end) || moment(start).isSame(end)
      if (!isStartDateBeforeEndDate) {
        this.$swal.fire({
          icon: 'error',
          title: 'วันที่สิ้นสุดต้องมากกว่าวันที่เริ่ม',
        })
        return
      }

      this.loadingSubmit = true
      if (this.$refs.form.validate()) {
        const data = {
          multiplyExp: this.datas.multiplyExp,
          startDate: start,
          endDate: end,
        }
        try {
          let res = await this.$store.dispatch('addBonusExp', data)
          if (res.status && res.status === 'success') {
            this.$swal.fire('สร้างสำเร็จ', '', 'success').then(action => window.location.reload())
            this.getBonusExpData(this.pagination.currentPage, this.pagination.rowsPerPage)
          } else {
            this.$swal.fire({
              icon: 'error',
              title: res.message,
            })
          }
        } catch (e) {
          if (
            !e.message.includes('Unexpected token') ||
            !e?.includes('Unexpected token') ||
            !e.message.includes('Unexpected identifier')
          ) {
            this.$swal.fire({
              icon: 'error',
              title: e.message,
            })
          }
        }
      }

      this.loadingSubmit = false
    },
    async deleteBonusExp(id) {
      this.$swal
        .fire({
          title: `คุณต้องการลบรายการนี้หรือไม่? id: ${id}`,
          showCancelButton: true,
          confirmButtonText: 'ลบ',
          cancelButtonText: 'ยกเลิก',
          confirmButtonColor: '#ff4c51',
          confirmButtonWidth: 150,
        })
        .then(async result => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch('deleteBonusExp', id)
              this.$swal.fire('ลบรายการสำเร็จ', '', 'success').then(action => window.location.reload())
              this.getBonusExpData(this.pagination.currentPage, this.pagination.rowsPerPage, this.search)
            } catch (e) {
              if (
                !e.message.includes('Unexpected token') ||
                !e?.includes('Unexpected token') ||
                !e.message.includes('Unexpected identifier')
              ) {
                this.$swal.fire({
                  icon: 'error',
                  title: e.message,
                })
              }
            }
          }
        })
    },
    digitsRule: value => {
      const digitsOnly = /^\d*\.?\d*$/
      if (!value) return 'จำนวนต้องไม่เป็นค่าว่าง'
      if (!digitsOnly.test(value)) return 'จำนวนต้องเป็นตัวเลขเท่านั้น'
      return true
    },
    multiplyRule: value => {
      if (value && value < 1) return 'จำนวนต้องมากกว่าหรือเท่ากับ 1'
      return true
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
<style scoped>
.title-btn {
  border: 1px solid var(--v-anchor-base);
  border-bottom: transparent;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
  transition: 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.title-btn.active {
  background: var(--v-anchor-base);
  color: #fff;
  font-weight: 700;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.img-bank {
  margin-top: 0px;
  padding: 0 !important;
}
.detail-bank {
  margin-top: 0px;
  padding: 0 !important;
}
.detail-bank-p {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  text-align: left;
}
.amount-col {
  display: flex;
  justify-content: space-between;
}
.amount-col span i {
  position: absolute;
  margin-left: 30px;
}
@media (max-width: 1400px) {
  .amount-col span i {
    position: relative;
    margin-left: 0 !important;
  }
}
@media (max-width: 550px) {
  .img-bank {
    margin-top: 20px;
  }
  .detail-bank {
    margin-top: 0px;
    padding-bottom: 15px !important;
  }
  .detail-bank-p {
    text-align: center;
  }
  .amount-col span i {
    position: relative;
    margin-left: 0;
  }
}
</style>
